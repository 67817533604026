<template>
  <div>
    <PDialog
      class="stepper-dialog"
      :header="''"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'auto' }"
      :containerStyle="{
        width: $screen.breakpoint == 'sm' ? '100vw' : '70vw',
        maxWidth: '900px',
      }"
      :aria-label="'CV Template Selection'"
    >
      <template #header>
        <div class="card shadow-3 px-0 border-0 mb-2 w-100">
          <div class="dialog-header-content">
            <h2 class="dialog-title">
              <span>Sélection de modèle de CV</span>
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger close-button"
                aria-label="Close dialog"
              />
            </h2>
            <PDivider />
          </div>
        </div>
      </template>

      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="template-grid">
          <div
            v-for="(cm, i) in cvModeles"
            :key="i"
            @click="editableItem.selectedTemplate = cm.fileUrl"
            class="template-card"
            :class="{ 'template-card--selected': editableItem.selectedTemplate == i }"
            role="button"
            tabindex="0"
            @keydown.enter="editableItem.selectedTemplate = cm.fileUrl"
            :aria-label="`Select template option ${cm.name}`"
            :aria-pressed="editableItem.selectedTemplate == cm.name"
          >
            <div class="template-preview">
              <img :src="cm.fullThumbnailUrl" :alt="cm.name" class="template-image" />
              <span class="template-number">{{ cm.name }}</span>
            </div>
          </div>
        </div>
      </ValidationObserver>

      <template #footer>
        <div class="dialog-footer">
          <PButton
            label="Annuler"
            icon="pi pi-times"
            @click="cancel"
            class="p-button-text"
            aria-label="Cancel and close dialog"
          />
          <PButton
            label="Télécharger"
            :disabled="editableItem.selectedTemplate == null"
            icon="pi pi-check"
            @click="save"
            class="download-button"
            aria-label="Download selected CV template"
          />
        </div>
      </template>
    </PDialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      note: null,
      display: false,
      selectedTemplate: null,
      editableItem: {
        selectedTemplate: null,
      },
      maxScore: 10,
      activeStepIndex: 0,
      cvModeles: [],
    };
  },
  created() {
    this.fetchCvModeles({
      extraQueryArg: "size=100",
    }).then(({ content }) => {
      this.cvModeles = content;
    });
  },
  methods: {
    ...mapActions({
      fetchCvModeles: "cvModele/fetchCvModeles",
    }),
    show() {
      this.display = true;
      this.$nextTick(() => {
        // Focus first template card when dialog opens for better keyboard navigation
        const firstCard = this.$el.querySelector(".template-card");
        if (firstCard) firstCard.focus();
      });
    },
    hide() {
      this.display = false;
      this.resetContent();
    },
    resetContent() {
      this.editableItem = {
        selectedTemplate: null,
      };
      this.activeStepIndex = 0;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
      });
      this.resetContent();
      this.hide();
    },
  },
};
</script>

<style scoped>
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #faf4fa;
  padding: 1.5rem;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
  padding: 1rem;
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
  padding: 1rem;
}

.dialog-header-content {
  width: 100%;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  font-size: 1.5rem;
  color: #2c3e50;
}

.close-button {
  margin-left: auto;
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.template-card {
  border: 2px solid #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: white;
  overflow: hidden;
}

.template-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.template-card--selected {
  border-color: #6366f1;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.2);
}

.template-preview {
  position: relative;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.template-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  flex-grow: 1;
}

.template-number {
  display: block;
  text-align: center;
  padding: 1rem;
  font-weight: 600;
  background: #f8f9fa;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-button {
  background-color: #6366f1;
  border-color: #6366f1;
}

.download-button:hover {
  background-color: #4f46e5 !important;
  border-color: #4f46e5 !important;
}

/* Accessibility improvements */
.template-card:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.5);
}
</style>
